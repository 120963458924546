<template>
  <div class="upload-certification-container">
    <input
      @change="handleFile"
      accept="application/pdf"
      id="select-certification"
      type="file"
    />
    <label for="select-certification">
      <span>Selecione o arquivo com a certificação</span>
      <span v-if="hasFile" for="file-name">{{ form.file.name }}</span>
      <i class="fa fa-file-pdf"></i>
    </label>
    <div class="upload-certification-button">
      <button @click="sendFile" type="button" :disabled="!hasFile || loading">
        Enviar
      </button>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import Vue from 'vue'

export default Vue.extend({
  props: { materialId: [Number, String] },
  data: () => ({
    loading: false,
    form: { file: {} }
  }),
  methods: {
    handleFile (e) {
      if (this.loading) return
      this.form.file = {}
      const files = e.target.files
      if (!files.length) return
      const [file] = files

      const { type, size } = file
      const acceptedTypes = ['application/pdf']

      if (!acceptedTypes.includes(type)) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'Os arquivos aceitos são no formato PDF.',
          icon: 'error'
        })
      }

      if (size > 2097152) {
        return swal({
          title: 'Arquivo inválido!',
          text: 'O arquivo deve ser menor que 2MB.',
          icon: 'error'
        })
      }

      this.form.file = file
    },
    async sendFile () {
      const form = new FormData()
      form.append('file', this.form.file)
      const url = `materials/${this.materialId}/certification`
      await this.$http.post(url, form)
      window.location.reload()
    }
  },
  computed: {
    hasFile () {
      const { file } = this.form
      return file.size && file.name
    }
  }
})
</script>

<style lang="sass" scoped>

.upload-certification-container
  margin-top: 1rem
  margin-bottom: 1rem
  position: relative

  input
    display: none

  label
    font-weight: 500
    background-color: #e6f7ec
    padding: 10px
    border-radius: 5px
    color: #16b666
    cursor: pointer
    display: flex
    flex-direction: column
    position: relative
    transition: 0.2s
    overflow: hidden

    &::before
      content: '',
      position: absolute
      width: 100%
      height: 100%
      left: -100%
      top: 0
      background-color: rgba(darken(#e6f7ec, 50%),0.1 )
      transition: 0.2s

    &:hover

      &::before
        left: 0

    span
      margin-bottom: 0.5rem
      width: 100%

      &:last-child
        margin-bottom: 0
        color: lighten(#16b666, 5%)

    i
      position: absolute
      right: 10px
      top: 50%
      transform: translateY(-50%)
      font-size: 26px

  .upload-certification-button
    display: flex
    justify-content: flex-end
    margin-top: 1rem

    button
      background-color: #16b666
      color: #e6f7ec
      padding: 10px 20px
      border-radius: 2rem
      transition: 0.3s

      &:hover
        background-color: darken(#16b666, 10%)

      &:disabled
        background-color: lighten(#16b666, 4%)
</style>
